import React from 'react';

import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useChallengeData } from '../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useUser } from '../../contexts/User/UserContext';
import { Dialog, Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { useLocalStorage } from '../../contexts/LocalStorage/LocalStorageContext';
import { Participant } from '@wix/ambassador-challenges-v1-participant/types';
import { Box } from '../Box';
import { ShareProgramSection } from './components/ShareProgramSection';
import { RewardsSection } from './components/RewardsSection';

import { classes } from './ChallengeCompletedModal.st.css';
import userTypeHandlers from '../../contexts/User/helpers/userTypeHandlers';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IChallengeCompletedModalProps {}

function getLocalStorageCompletedChallengeKey(participant: Participant) {
  return `user-already-seen-complete-modal-${participant?.id}`;
}

export const ChallengeCompletedModal: FCWithChildren<
  IChallengeCompletedModalProps
> = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { challengeData } = useChallengeData();
  const { participant, userType } = useUser();
  const { setItem, requestItem, storage } = useLocalStorage();
  const [modalAlreadyShowed, setModalAlreadyShowed] = React.useState(true);
  const [opened, setOpened] = React.useState(true);

  React.useEffect(() => {
    requestItem?.(getLocalStorageCompletedChallengeKey(participant));
  }, []);

  React.useEffect(() => {
    if (storage?.[getLocalStorageCompletedChallengeKey(participant)] === null) {
      setModalAlreadyShowed(false);
    }
  }, [storage, setModalAlreadyShowed]);

  if (modalAlreadyShowed || !userTypeHandlers.isCompleted(userType)) {
    return null;
  }

  const programName = challengeData.challenge.settings.description.title;

  return (
    <Dialog
      notFullscreenOnMobile={true}
      isOpen={opened}
      onClose={() => {
        setItem(getLocalStorageCompletedChallengeKey(participant), true);
        setOpened(false);
      }}
    >
      <Box
        paddingLeft={isMobile ? undefined : 12}
        paddingRight={isMobile ? undefined : 12}
        tag="section"
        direction="column"
        textAlign="center"
        alignItems="center"
      >
        <Text
          className={classes.text}
          tagName="h1"
          typography={
            isMobile ? TextTypography.smallTitle : TextTypography.largeTitle
          }
        >
          {t('live.challenges-page.modal.challenge-completed.title')}
        </Text>
        <Box marginTop={6}>
          <Text tagName="p" className={classes.text}>
            <Trans
              i18nKey="live.challenges-page.modal.challenge-completed.info"
              components={{ bold: <strong /> }}
              values={{
                progress: `${participant.performance}%`,
                challengeName: programName.trim(),
              }}
            />
          </Text>
        </Box>
        <RewardsSection />
        <ShareProgramSection marginTop={isMobile ? 6 : 2} />
      </Box>
    </Dialog>
  );
};
