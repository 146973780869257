import React from 'react';

import { st, classes } from './Badges.st.css';
import { useBadges } from '../../contexts/Badges/BadgesContext';
import { Image } from 'wix-ui-tpa/cssVars';
import { FCWithChildren } from '@wix/challenges-web-library';

// TODO: shall be deprecated once experiment enableServerlessChallenge is finished
export interface IBadge {
  id: string;
  title: string;
  description: string;
  backgroundColor: string;
  textColor: string;
  icon: string;
  roleId: string;
  hasPermissions: boolean;
  slug: string;
  createDate: string;
  updateDate: string;
}

export enum ALIGNMENT {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface IBadgesProps {
  title?: React.ReactNode;
  alignment?: ALIGNMENT;
  removeCustomBottomMargin?: boolean;
}

export const AwardsBadges: FCWithChildren<IBadgesProps> = (props) => {
  const { badges } = useBadges();

  return (
    <div
      className={st(classes.root, {
        align: props.alignment || ALIGNMENT.left,
        removeCustomBottomMargin: props.removeCustomBottomMargin,
      })}
    >
      {!!badges.length && props.title}
      <div className={classes.badgeList}>
        {badges.map((badge, idx) => (
          <span
            key={`${badge.title}-${idx}`}
            className={classes.badge}
            style={{
              color: badge.textColor,
              background: badge.backgroundColor,
            }}
          >
            {!!badge.icon && (
              <div className={classes.image}>
                <Image
                  src={badge.icon}
                  forceImageContain={true}
                  alt={badge.slug}
                />
              </div>
            )}
            <span className={classes.text}>{badge.title}</span>
          </span>
        ))}
      </div>
      {}
    </div>
  );
};
