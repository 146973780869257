import { ChallengeServerlessData } from '../../api/challenges/challenge.api';
import {
  ChallengeSection,
  ChallengeStep,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { useControllerData } from '../main/controllerContext';

export interface IChallengeDataContext {
  isDescriptionShown: boolean;
  challengeData?: ChallengeServerlessData;
  challengeSections?: ChallengeSection[];
  challengeSteps?: ChallengeStep[];
  requestChallengeSections?: Function;
  requestChallengeSteps?: Function;
  isMyWalletInstalled: boolean;
  isProfileInstalled: boolean;
  profileUrl: string;
}

export const challengeDataDefaultValue = {
  isDescriptionShown: false,
  challengeData: null,
  challengeSections: null,
  challengeSteps: null,
  requestChallengeSections: null,
  requestChallengeSteps: null,
  isMyWalletInstalled: false,
  isProfileInstalled: false,
  profileUrl: null,
};

export function useChallengeData(): IChallengeDataContext {
  const props = useControllerData();
  return {
    isDescriptionShown: props.isDescriptionShown,
    challengeData: props.challengeData,
    challengeSections: props.challengeSections,
    challengeSteps: props.challengeSteps,
    requestChallengeSections: props.requestChallengeSections,
    requestChallengeSteps: props.requestChallengeSteps,
    isMyWalletInstalled: props.isMyWalletInstalled,
    isProfileInstalled: props.isProfileInstalled,
    profileUrl: props.profileUrl,
  };
}
