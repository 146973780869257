import React from 'react';
import { classes, st } from './SocialShare.st.css';
import { SocialBar, CopyUrlButton } from 'wix-ui-tpa/cssVars';
import { LinkedIn, Facebook, Twitter, Link } from 'wix-ui-icons-common';
import { ButtonNames } from '../../contexts/main/biInterfaces';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ISocialShareProps {
  shareTitle: string;
  shareUrl: string;
  originalUrl: string;
  className?: string;
}

export interface ISocialShareState {}

export const SocialShare: FCWithChildren<ISocialShareProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const { shareTitle, shareUrl, originalUrl, className } = props;
  const iconProps = {};

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
        },
        className,
      )}
    >
      <SocialBar className={classes.socialShareBar}>
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.facebook')}
          aria-label={t('live.challenges-page.social-share.facebook')}
          icon={<Facebook {...iconProps} />}
          href={`//facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareTitle}`}
          // @ts-expect-error
          target="_blank"
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ShareFacebook,
              }),
            );
          }}
        />
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.twitter')}
          aria-label={t('live.challenges-page.social-share.twitter')}
          icon={<Twitter {...iconProps} />}
          href={`//twitter.com/intent/tweet?text=${shareTitle}&url=${originalUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ShareTwitter,
              }),
            );
          }}
        />
        <SocialBar.Icon
          tooltip={t('live.challenges-page.social-share.linkedin')}
          aria-label={t('live.challenges-page.social-share.linkedin')}
          icon={<LinkedIn {...iconProps} />}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
          // @ts-expect-error
          target="_blank"
          onClick={async () => {
            await bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.ShareLinkedin,
              }),
            );
          }}
        />

        {originalUrl ? (
          <CopyUrlButton
            icon={<Link {...iconProps} />}
            aria-label={t('live.challenges-page.social-share.copy-link')}
            tooltipText={t('live.challenges-page.social-share.copy-link')}
            successText={t(
              'live.challenges-page.social-share.copy-link-reaction',
            )}
            url={originalUrl}
            onClick={async () => {
              await bi.report(
                memberWebAppButtonClickV2({
                  buttonName: ButtonNames.CopyLink,
                }),
              );
            }}
          />
        ) : null}
      </SocialBar>
    </div>
  );
};

SocialShare.displayName = 'SocialShare';
